<template>
  <div
    id="portfolio-container"
    class="portfolio-container portfolio-overlay dark text-overlay centered row isotope"
  >
    <div v-if="!items.length">
      <div class="alert alert-warning mt-3" role="alert">
        You don't own any Hashmasks. Go get one!
      </div>
    </div>
    <blockquote
      v-if="isEditing"
      class="sale-section ml-2 w-100 m-0"
      style="overflow-wrap: break-word; line-height: 20px"
    >
      Note: A Hashmask can only exist in one collection at a time. If the mask
      you choose already exists in another collection, it will be removed from
      the other one.
    </blockquote>
    <div
      class="portfolio-item col-md-3 webdesign"
      v-for="item in items"
      :key="item.index"
      @click="handleSelectMaskItem(item)"
    >
      <component :is="isEditing ? 'div' : 'a'" :href="'/detail/' + item.index">
        <div
          class="portfolio-content"
          :class="{
            'portfolio-item-selected':
              selectedItems.includes(item.index) && isEditing,
          }"
        >
          <div class="portfolio-img-content">
            <img v-bind:src="getRevealedMaskUrl(item.index)" />
          </div>
          <div class="portfolio-text-content">
            <div class="portfolio-text">
              <h3>#{{ item.index }}</h3>
              <h3>{{ item.name || 'Unnamed' }}</h3>
            </div>
          </div>
        </div>
      </component>
    </div>
  </div>
</template>

<script>
import { getRevealedMaskIndex } from '../helpers/contract.js';
import { HASHMASKS_THUMBS_URL } from '../assets/constants';
export default {
  name: 'MaskItems',
  props: {
    items: { required: true, type: Array },
    selectedItems: { required: false, type: [Array], default: () => [] },
    isEditing: { required: false, type: Boolean, default: true },
    handleSelectMaskItem: { required: true, type: Function },
  },
  methods: {
    getRevealedMaskUrl: function (nftIndex) {
      const id = getRevealedMaskIndex(nftIndex);
      return `${HASHMASKS_THUMBS_URL}${id}.png`;
    },
  },
};
</script>

<style></style>
