<template>
  <div id="content-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="project-page col-sm-12 mt-0" style="text-align: left">
            <h2>Collection</h2>
            <blockquote
              class="sale-section ml-2 w-100 m-0"
              style="overflow-wrap: break-word; line-height: 20px"
            >
              Note: Creating a collection or voting doesn't cost any gas. You
              will be required to sign a message (Free and off-chain)
            </blockquote>
            <!-- Description Wrap -->
            <div v-if="step === 'firstStep'" class="row mt-5">
              <div class="col-sm-12">
                <div class="row">
                  <h6 class="m-0 col-md-2">Title</h6>
                  <div class="col-md-10 count-wrapper">
                    <input
                      type="text"
                      class="w-100 p-2"
                      v-model="title"
                      placeholder="Title"
                      :maxlength="maxTitleLength"
                    />
                    <p class="chars-left">
                      {{ maxTitleLength - title.length }} characters left
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <h6 class="m-0 col-md-2">Description</h6>
                  <div class="col-md-10">
                    <textarea
                      class="w-100 p-2"
                      v-model="description"
                      placeholder="Description"
                      :maxlength="maxDescriptionLength"
                    />
                    <p class="chars-left">
                      {{ maxDescriptionLength - description.length }} characters
                      left
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-4 col-sm-12 d-flex justify-content-end">
                <button v-on:click="handleNext" class="mint-button m-0">
                  Next
                </button>
              </div>
            </div>
            <!-- Description Wrap -->
            <!-- Portfolio Wrap -->
            <div v-else class="portfolio-wrap mt-5">
              <div>
                <div class="d-flex">
                  <h6 class="m-0 col-md-2">Title:</h6>
                  <p
                    class="sale-section ml-2 p-0 col-md-10"
                    style="line-height: 20px"
                  >
                    {{ title }}
                  </p>
                </div>
                <div class="d-flex mt-2">
                  <h6 class="m-0 col-md-2">Description:</h6>
                  <p
                    class="sale-section ml-2 p-0 w-100 col-md-10"
                    style="overflow-wrap: break-word; line-height: 20px"
                  >
                    {{ description }}
                  </p>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center mt-4"
              >
                <!-- Portfolio Filter -->
                <div class="filters">
                  <ul id="filters" class="ml-0">
                    <li class="active" data-filter="*" style="font-size: 18px">
                      Owned Hashmasks
                      <span class="number-of-masks">{{
                        maskItems.length
                      }}</span>
                    </li>
                  </ul>
                </div>
                <!-- Portfolio Filter -->
                <div>
                  <button v-on:click="handleCreate" class="mint-button m-0">
                    Create
                  </button>
                </div>
              </div>
              <!-- Portfolio Items -->
              <mask-items
                :items="maskItems"
                :selectedItems="selectedItems"
                :handleSelectMaskItem="handleSelectItem"
              />
              <!-- Portfolio Items Ends -->
            </div>
            <!-- Portfolio Wrap Ends -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  tokenOfOwnerByIndex,
  hashmasksBalanceOf,
  getNFTName,
  isValidETHAddress,
  generateSignature,
  getEthAddress,
} from '../helpers/contract.js';
import Vue from 'vue';
import {
  doesCollectionWithNameExist,
  upsertCollection,
} from '../helpers/database.worker';
import { collectionInfoValidate, generateDataToSign } from '../helpers/utils';
import MaskItems from '../components/MaskItems';

export default {
  name: 'CollectionCreate',
  components: { MaskItems },
  data() {
    return {
      maskItems: [],
      title: '',
      description: '',
      selectedItems: [],
      step: 'firstStep',
      maxDescriptionLength: 600,
      maxTitleLength: 25,
    };
  },
  async mounted() {
    await this.getMaskItems();
  },
  methods: {
    getMaskItems: async function() {
      const balance = await hashmasksBalanceOf();
      // const balance = 10;
      for (let i = 0; i < balance; i++) {
        const index = await tokenOfOwnerByIndex(i);
        // const index = i;
        const name = await getNFTName(index);
        if (this.maskItems.length < balance) {
          this.maskItems.push({ index, name });
        }
      }
    },
    handleCreate: async function() {
      const walletAddress = await getEthAddress();
      if (!walletAddress) return;
      if (this.selectedItems.length < 2) {
        alert('Please select at least 2 masks');
        return;
      }
      // Validataion
      if (!isValidETHAddress(walletAddress)) {
        alert('Use the valid ETH address');
        return;
      }
      // Check the name duplication
      const searchParams = {
        name: this.title,
        from: walletAddress,
      };
      const isExistName = await doesCollectionWithNameExist({
        params: searchParams,
      });
      if (isExistName) {
        alert('Same title is exist! Please use another title');
        return;
      }
      // Generate sign signature
      const signParams = {
        action: 'upsert',
        name: this.title,
        description: this.description,
        is_edit: false,
        from: walletAddress,
        timestamp: Date.now(),
      };
      const dataToSign = generateDataToSign(signParams);
      const signedString = await generateSignature(dataToSign);
      if (!signedString) {
        alert('Connect to the wallet');
        return;
      }
      // Call the backend api
      const params = {
        name: this.title,
        description: this.description,
        is_edit: false,
        maskIds: this.selectedItems.map((id) => id.toString()),
        created_by: walletAddress,
        timestamp: signParams.timestamp,
        signature: signedString,
      };
      try {
        const collection = await upsertCollection(params);
        Vue.noty.success('Successfully created a collection');
        this.$router.push({
          name: 'collection',
          params: { id: collection.id },
        });
      } catch (e) {
        Vue.noty.error('An error has occurred. Please try again');
      }
    },
    handleSelectItem: function(selectedItem) {
      let index = this.selectedItems.findIndex(
        (item) => selectedItem.index === item,
      );
      if (index === -1)
        this.selectedItems = [...this.selectedItems, selectedItem.index];
      else this.selectedItems.splice(index, 1);
    },
    handleNext: function() {
      if (!collectionInfoValidate(this.title, this.description)) return;
      this.step = 'secondStep';
    },
  },
};
</script>

<style></style>
